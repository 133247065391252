import "./styles.scss"

import React from "react"

const Mission = () => {
  return (
    <section className="about-mission">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-4">
            <h2>Nasza misja</h2>
          </div>
          <div className="col-lg-8">
            <h3>
              Biznes w modelu win-win. Budujemy wygodne mieszkania, w świetnych
              lokalizacjach, dając jednocześnie naszym Klientom możliwość
              inwestowania w realizowane przez nas projekty deweloperskie.
            </h3>
          </div>
        </div>

        <div className="row row--content align-items-center">
          <div className="col-lg-4">
            <div
              className="about-mission__image"
              style={{
                backgroundImage: `url('${
                  require("assets/images/about/about-mission-image.jpg").default
                }')`,
              }}
            />
          </div>
          <div className="col-lg-7 offset-lg-1">
            <div className="about-mission__item">
              <h4>
                <span>01/</span> Zakup działek
              </h4>
              <p>
                Dział ekspansji koncentruje się na najlepszych lokalizacjach,
                które odpowiadają najwyższym oczekiwaniom.
              </p>
            </div>
            <div className="about-mission__item">
              <h4>
                <span>02/</span> Pozwolenia i projekt architektoniczny
              </h4>
              <p>
                Dział przygotowania inwestycji kładzie nacisk na jakość projektu
                oraz dostosowuje go do najwyższych standardów.
              </p>
            </div>
            <div className="about-mission__item">
              <h4>
                <span>03/</span> Budowa
              </h4>
              <p>
                Dzięki naszym sprawdzonym ekipom budowlanym ten kluczowy proces
                przebiega sprawnie i według założonego harmonogramu.
              </p>
            </div>
            <div className="about-mission__item">
              <h4>
                <span>04/</span> Wynajem lub sprzedaż
              </h4>
              <p>
                Specjaliści ds. sprzedaży i wynajmu nieruchomości komercjalizują
                oraz sprzedają powierzchnię obiektów.
              </p>
            </div>
            <div className="about-mission__item">
              <h4>
                <span>05/</span> Komfortowe życie
              </h4>
              <p>
                W dobrze zaprojektowanej inwestycji, w świetnej okolicy.
                Zamieszkaj mieszkaniu o standardzie Trust.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Mission
